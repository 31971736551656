import * as yup from 'yup'

import { companyLegalEntitySchema } from '../company'
import { paymentInformationModelSchema } from '../payment'
import {
  possiblePurchaseOrderAssetsAllocationAction,
  purchaseOrderAssetAssigneeSchema,
  PurchaseOrderAssetsAllocationActionType,
} from '../purchase'
import { purchasePriorities } from '../purchase/purchase-order-priority.schemas'
import { adminSchema } from '../user'

import { orderAssetsModelSchema } from './order-assets.schemas'
import {
  orderCommercialOfferPackageModelSchema,
  purchaseOrderCommercialOfferForMutationModelSchema,
  purchaseOrderCommercialOfferModelSchema,
} from './order-commercial-offer.schemas'
import { orderContactInformationSchema } from './order-contact-information.schemas'
import { orderDeliveryModelSchema } from './order-delivery.schemas'
import { orderDiscountModelSchema } from './order-discount.schemas'
import { orderDisputeSchema } from './order-dispute.schemas'
import { orderPONumberModelSchema } from './order-po-number.schemas'
import { orderProductsModelSchema } from './order-product.schemas'
import { purchaseOrderShippingInfoSchema } from './order-shipping-info.schemas'
import { orderStatusLogModelSchema } from './order-status-log.schemas'
import { orderStatusSchema } from './order-statuses.schemas'
import { orderSubtypeSchema, orderTypeSchema } from './order-type.schemas'
import { orderViewStepSchema } from './order-view-steps.schemas'

export const orderModelSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: yup.string().required().defined(),
    customOrderName: yup.string().defined().nullable(),
    price: yup.number().nullable().defined(),
    notes: yup.string().nullable().defined(),
    type: orderTypeSchema.defined(),
    subtype: orderSubtypeSchema.defined(),
    status: orderStatusSchema,
    admin: adminSchema.defined(),
    adminId: yup.string().required().defined(),
    companyId: yup.string().required().defined(),
    companyLegalEntity: companyLegalEntitySchema.nullable().defined(),
    companyLegalEntityId: yup.string().nullable().defined(),
    contactInformation: orderContactInformationSchema.nullable().defined(),
    createdAt: yup.string().required().defined(),
    delivery: orderDeliveryModelSchema.defined(),
    paymentInformation: paymentInformationModelSchema.nullable().defined(),
    paymentInformationId: yup.string().nullable().defined(),
    step: orderViewStepSchema.defined(),
    updatedAt: yup.string().defined(),
    orderProducts: orderProductsModelSchema.defined(),
    cancelReason: yup.string().nullable().defined(),
    purchaseOrderNumber: yup.string().nullable().defined(),
    totalPrice: yup.number().nullable().defined(),
    taxes: yup.number().nullable().defined(),
    orderMarginPercent: yup.number().defined().nullable(),
    commercialOffer: purchaseOrderCommercialOfferModelSchema.defined().nullable(),
    commercialOfferForMutation: purchaseOrderCommercialOfferForMutationModelSchema
      .defined()
      .nullable(),
    statusLog: yup.array(orderStatusLogModelSchema).defined(),
    invoiceLink: yup.string().nullable().defined(),
    autoApprovalDate: yup.date().nullable().defined(),
    confirmedByUserAt: yup.string().defined(),
    author: yup.string().optional(),
    commercialOffers: yup.array(purchaseOrderCommercialOfferModelSchema).defined().nullable(),
    trackingNumber: yup.string().nullable().defined(),
    openedDisputeForm: orderDisputeSchema.nullable().defined(),
    closedDisputeForms: yup.array().of(orderDisputeSchema).defined().nullable(),
    companyName: yup.string().defined(),
    shippingInfo: purchaseOrderShippingInfoSchema.nullable().defined(),
    pqLink: yup.string().nullable().defined(),
    lastComment: yup
      .object({
        id: yup.string().required(),
        title: yup.string().required(),
        content: yup.string().required(),
      })
      .nullable(),
    orderNeedSerialNumber: yup.boolean().defined(),
    assetAssignee: yup
      .object({
        action: yup
          .mixed<PurchaseOrderAssetsAllocationActionType>()
          .oneOf(possiblePurchaseOrderAssetsAllocationAction)
          .defined(),
        assignee: purchaseOrderAssetAssigneeSchema.defined(),
      })
      .defined()
      .nullable(),
    commercialOfferPackages: yup
      .array(orderCommercialOfferPackageModelSchema)
      .defined()
      .default([]),
    onboardingDate: yup.string().defined().nullable(),
    orderAssets: orderAssetsModelSchema,
    asanaLink: yup.string().defined().nullable(),
    vendorDepId: yup.string().defined().nullable(),
    priorities: purchasePriorities.defined(),
    vendorResponseFormsInfo: yup
      .object({
        total: yup.number().defined(),
        filled: yup.number().defined(),
      })
      .defined(),
    discount: orderDiscountModelSchema.defined().nullable(),
  })
  .concat(orderPONumberModelSchema)
  .noUnknown()

export type OrderModel = yup.InferType<typeof orderModelSchema>
