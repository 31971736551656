import { ApiError } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { VRFModelSchema } from '@share/schemas'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  vendorResponseForms: yup.array().of(VRFModelSchema.defined()).defined(),
})

type GetVRFByIdRequestParams = {
  id: string
}

type GetVRFResponse = yup.InferType<typeof responseSchema>

type GetVRFByIdRequest = {
  params: GetVRFByIdRequestParams
  options?: UseQueryOptions<GetVRFResponse, ApiError>
}

const getVRFByIdRequest = async ({ id }: GetVRFByIdRequestParams) =>
  apiRequest<GetVRFResponse>({
    method: 'GET',
    url: API_LIST.vrf.id(id),
    responseSchema,
  })

export const useGetVRFByIdRequest = ({ params, options }: GetVRFByIdRequest) =>
  useQuery<GetVRFResponse, ApiError>({
    queryKey: [API_TAGS.vrf.root, params],
    queryFn: () => getVRFByIdRequest(params),
    ...options,
  })

export const invalidateVRFById = (params: GetVRFByIdRequestParams) =>
  queryClient.invalidateQueries([API_TAGS.vrf.root, params])
