import * as yup from 'yup'

import { companyLegalEntitySchema } from '../company'
import {
  orderCommercialOffersModelSchema,
  orderDiscountModelSchema,
  orderPONumberModelSchema,
  orderStatusesLogModelSchema,
  orderSubtypeSchema,
} from '../order'
import { orderDisputeSchema } from '../order/order-dispute.schemas'
import { paymentInformationModelSchema } from '../payment'
import {
  possiblePurchaseOrderAssetsAllocationActionSchema,
  purchaseOrderAssetAssigneeSchema,
} from '../purchase'

import {
  deliveryAssetsTypesSchema,
  deliveryEntityTypeSchema,
  deliveryOrderTypeSchema,
} from './delivery.consts.schemas'
import { deliveryBoxConfigModelSchema } from './delivery-boxes.schemas'
import { deliveryOrderCommercialOfferModelSchema } from './delivery-commercial-offer.schemas'
import { deliveryInfoSchema } from './delivery-info.schemas'
import {
  deliveryOrderInvoiceAssetsSchema,
  deliveryOrderInvoiceItemsSchema,
  deliveryOrderInvoiceNonAssetsSchema,
} from './delivery-invoice.schemas'
import { deliveryOrderAssetsSchema } from './delivery-order-assets.schemas'
import { deliveryOrderPickupInfoSchema } from './delivery-pickup.schemas'
import { deliveryOrderShippingInfoSchema } from './delivery-shipping-info.schemas'
import { deliveryOrderStatusSchema } from './delivery-statuses.schemas'
import { draftDeliveryStepsSchema } from './delivery-steps.schemas'

export const draftDeliverySchema = yup.object().shape({
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  id: yup.string().defined(),
  notes: yup.string().defined().nullable(),
  step: draftDeliveryStepsSchema.defined(),
  steps: yup.array().of(draftDeliveryStepsSchema.defined()).defined(),
  type: deliveryEntityTypeSchema.defined(),
  boxConfig: deliveryBoxConfigModelSchema.defined().nullable(),
  deliveryType: deliveryAssetsTypesSchema.defined(),
  customOrderName: yup.string().defined().nullable(),
  pickUpInfo: deliveryOrderPickupInfoSchema.defined().nullable(),
  shippingInfo: deliveryOrderShippingInfoSchema.defined().nullable(),
  orderAssets: deliveryOrderAssetsSchema.defined(),
  statusLog: orderStatusesLogModelSchema.defined(),
  openedDisputeForm: orderDisputeSchema.defined().nullable(),
  closedDisputeForms: yup.array().of(orderDisputeSchema).defined().nullable(),
  delivery: deliveryInfoSchema.defined().nullable(),
  companyLegalEntity: companyLegalEntitySchema.defined().nullable(),
  paymentInformation: paymentInformationModelSchema.defined().nullable(),
  commercialOffers: orderCommercialOffersModelSchema.defined(),
  invoiceItems: deliveryOrderInvoiceItemsSchema.defined().nullable(),
  invoiceAssetItems: deliveryOrderInvoiceAssetsSchema.defined().nullable(),
  invoiceNonAssetItems: deliveryOrderInvoiceNonAssetsSchema.defined().nullable(),
  deliveryOrderType: deliveryOrderTypeSchema.defined(),
  assetAssignee: yup
    .object({
      action: possiblePurchaseOrderAssetsAllocationActionSchema.defined(),
      assignee: purchaseOrderAssetAssigneeSchema.defined(),
    })
    .defined()
    .nullable(),
})

export type DraftDeliveryModel = yup.InferType<typeof draftDeliverySchema>

export const deliveryOrderSchema = yup
  .object()
  .shape({
    autoApprovalDate: yup.string().defined().nullable(),
    boxConfig: deliveryBoxConfigModelSchema.defined().nullable(),
    commercialOffer: deliveryOrderCommercialOfferModelSchema.defined().nullable(),
    companyLegalEntity: companyLegalEntitySchema.defined(),
    createdAt: yup.string().defined(),
    updatedAt: yup.string().defined(),
    customOrderName: yup.string().defined().nullable(),
    delivery: deliveryInfoSchema.defined(),
    orderAssets: deliveryOrderAssetsSchema.defined(),
    deliveryType: deliveryAssetsTypesSchema.defined(),
    id: yup.string().defined(),
    name: yup.string().defined(),
    notes: yup.string().defined().nullable(),
    openedDisputeForm: orderDisputeSchema.defined().nullable(),
    closedDisputeForms: yup.array().of(orderDisputeSchema).defined().nullable(),
    paymentInformation: paymentInformationModelSchema.defined(),
    pickUpInfo: deliveryOrderPickupInfoSchema.defined(),
    shippingInfo: deliveryOrderShippingInfoSchema.defined(),
    status: deliveryOrderStatusSchema.defined(),
    statusLog: orderStatusesLogModelSchema.defined(),
    step: draftDeliveryStepsSchema.defined(),
    steps: yup.array().of(draftDeliveryStepsSchema.defined()).defined(),
    type: deliveryEntityTypeSchema.defined(),
    subtype: orderSubtypeSchema.defined(),
    createdBy: yup.string().defined(),
    invoiceLink: yup.string().defined().nullable(),
    cancelReason: yup.string().defined().nullable(),
    companyName: yup.string().defined(),
    companyId: yup.string().defined(),
    invoiceItems: deliveryOrderInvoiceItemsSchema.defined().nullable(),
    invoiceAssetItems: deliveryOrderInvoiceAssetsSchema.defined().nullable(),
    invoiceNonAssetItems: deliveryOrderInvoiceNonAssetsSchema.defined().nullable(),
    deliveryOrderType: deliveryOrderTypeSchema.defined(),
    totalPrice: yup.number().defined().nullable(),
    pqLink: yup.string().nullable().defined(),
    lastComment: yup
      .object({
        id: yup.string().defined(),
        title: yup.string().defined(),
        content: yup.string().defined(),
      })
      .defined()
      .nullable(),
    assetAssignee: yup
      .object({
        action: possiblePurchaseOrderAssetsAllocationActionSchema.defined(),
        assignee: purchaseOrderAssetAssigneeSchema.defined(),
      })
      .defined()
      .nullable(),
    orderMarginPercent: yup.number().defined().nullable(),
    onboardingDate: yup.string().defined().nullable(),
    offboardingDate: yup.string().defined().nullable(),
    confirmedByUserAt: yup.string().defined(),
    asanaLink: yup.string().defined().nullable(),
    discount: orderDiscountModelSchema.defined().nullable(),
  })
  .concat(orderPONumberModelSchema)

export type DeliveryOrderModel = yup.InferType<typeof deliveryOrderSchema>
