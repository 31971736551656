import { assetConditionStatesTypeSchema } from '@share/schemas/asset/asset-condition-state.schemas'
import { categoryModelSchema } from '@share/schemas/categories'
import * as yup from 'yup'

export const VRFProductSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  mpn: yup.string().defined().nullable(),
  quantity: yup.number().defined(),
  category: categoryModelSchema.omit(['code']).defined(),
  subcategory: yup.string().defined(),
  image: yup.string().defined().nullable(),
  condition: assetConditionStatesTypeSchema.defined().nullable(),
  keyboardLayout: yup.string().defined().nullable(),
  powerSupplyType: yup.string().defined().nullable(),
  note: yup.string().defined().nullable(),
  warranty: yup
    .object({ name: yup.string().defined().nullable(), id: yup.string().defined().nullable() })
    .defined(),
  warranties: yup
    .array()
    .of(yup.object({ name: yup.string().defined(), id: yup.string().defined() }))
    .defined(),
  additionalServices: yup
    .array()
    .of(yup.object({ name: yup.string().defined() }))
    .defined(),
})

export type VRFProductModel = yup.InferType<typeof VRFProductSchema>

export const VRFAlternativeProductSchema = yup.object().shape({
  id: yup.string().defined(),
  image: yup.string().nullable(),
  mpn: yup.string().defined().nullable(),
  name: yup.string().defined(),
  category: categoryModelSchema.omit(['code']).defined(),
  subcategory: yup.string().defined(),
})

export type VRFAlternativeProductModel = yup.InferType<typeof VRFProductSchema>
