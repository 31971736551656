import { memo } from 'react'
import type { DrawerProps as DrawerUIProps } from '@dots/uikit'
import { Drawer as DrawerUI, Typography } from '@dots/uikit'
import { DRAWER_CONTAINER_ID } from '@share/constants'

export interface DrawerNewProps extends DrawerUIProps {
  title: string
  footer?: React.ReactNode
  trigger?: React.ReactNode
}

export const DrawerNew: React.FC<DrawerNewProps> = ({
  open,
  defaultOpen,
  modal,
  trigger,
  children,
  title,
  footer,
  onOpenChange,
}) => {
  const portalContainer = document.getElementById(DRAWER_CONTAINER_ID)

  return (
    <DrawerUI.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange} modal={modal}>
      {trigger && <DrawerUI.Trigger asChild>{trigger}</DrawerUI.Trigger>}

      <DrawerUI.Content container={portalContainer}>
        <DrawerUI.Header>
          <Typography variant='h2'>{title}</Typography>
        </DrawerUI.Header>

        <DrawerUI.Body>{children}</DrawerUI.Body>

        <DrawerUI.Footer gap={24}>{footer}</DrawerUI.Footer>
      </DrawerUI.Content>
    </DrawerUI.Root>
  )
}

export const DrawerNewMemoized = memo(DrawerNew)
