import { currencyModelSchema } from '@share/schemas/currency'
import * as yup from 'yup'

import { VRFAlternativeProductSchema, VRFProductSchema } from './vrf-product.schema'
import { VRFVendorResponseSchema } from './vrf-vendor-response.schema'

const possibleVRFStatuses = [
  'FULLY',
  'PARTIALLY',
  'ALTERNATIVE',
  'NOT_PROVIDE',
  'PARTIALLY_ALTERNATIVE',
  'WAITING',
] as const

export type VRFStatuses = (typeof possibleVRFStatuses)[number]

const VRFStatusesSchema = yup.mixed<VRFStatuses>().oneOf(possibleVRFStatuses)

export const VRFModelSchema = yup.object({
  vrfFormId: yup.string().defined(),
  status: VRFStatusesSchema.defined(),
  validUntil: yup.string().defined(),
  tax: yup.number().defined().nullable(),
  percentageTax: yup.number().defined().nullable(),
  totalBeforeTax: yup.number().defined().nullable(),
  total: yup.number().defined().nullable(),
  name: yup.string().defined(),
  email: yup.string().defined(),
  deliveryAddress: yup.string().defined(),
  requestedETA: yup
    .object({
      startDate: yup.string().defined().nullable(),
      endDate: yup.string().defined().nullable(),
    })
    .defined(),
  vendorName: yup.string().defined().nullable(),
  currency: currencyModelSchema.defined().nullable(),
  products: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          orderProductInfo: VRFProductSchema.defined(),
          vendorResponse: VRFVendorResponseSchema.defined().nullable(),
          alternativeInfo: VRFAlternativeProductSchema.optional().default(undefined),
        })
        .defined(),
    )
    .defined(),
})

export type VRFModel = yup.InferType<typeof VRFModelSchema>
