import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES } from '@share/constants'

import { AuthLayout, MainLayout } from '../layouts'

import { PrivateRoute, PublicRoute, RouterError } from './components'

// Auth
const LoginPage = lazy(() => import('@pages/login/page-login'))

// Orders
const OrdersViewPage = lazy(() => import('@pages/orders/page-view'))

// Purchase
const PurchaseOrderDetailsViewPage = lazy(() => import('@pages/purchase/page-purchase-order-view'))
const PurchaseOrderEditDeliveryPage = lazy(
  () => import('@pages/purchase/slug/edit/page-purchase-order-edit-delivery'),
)
const PurchaseOrderEditShippingPage = lazy(
  () => import('@pages/purchase/slug/edit/page-purchase-order-edit-shipping'),
)
const PurchaseOrderEditPaymentPage = lazy(
  () => import('@pages/purchase/slug/edit/page-purchase-order-edit-payment'),
)
const PurchaseOrderCreateCommercialOfferPage = lazy(
  () => import('@pages/purchase/page-purchase-order-create-commercial-offer'),
)
const PurchaseOrderEditCommercialOfferPage = lazy(
  () => import('@pages/purchase/page-purchase-order-edit-commercial-offer'),
)
const PurchaseOrderOfferAlternativePage = lazy(
  () => import('@pages/purchase/page-purchase-order-offer-alternative'),
)
const PurchaseOrderOfferAlternativeManuallyPage = lazy(
  () => import('@pages/purchase/page-purchase-order-offer-alternative-manually'),
)

// Vendors
const VendorsViewPage = lazy(() => import('@pages/vendors/page-view'))
const VendorCreatePage = lazy(() => import('@pages/vendors/page-create'))
const VendorEditPage = lazy(() => import('@pages/vendors/page-edit'))

// Companies
const CompaniesViewPage = lazy(() => import('@pages/companies/page-view'))

const CompaniesAdminsViewPage = lazy(() => import('@pages/companies-admins/page-view'))

// Catalog
const CatalogViewPage = lazy(() => import('@pages/catalog/page-view'))
const CatalogProductCreateManuallyPage = lazy(
  () => import('@pages/catalog/page-create-catalog-product-manually'),
)
const CatalogProductEditManuallyPage = lazy(
  () => import('@pages/catalog/page-edit-catalog-product-manually'),
)

// Delivery
const DeliveryOrderDetailViewPage = lazy(() => import('@pages/delivery/page-delivery-order-view'))
const DeliveryOrderEditPickupPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-pickup'),
)
const DeliveryOrderEditDeliveryPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-delivery'),
)
const DeliveryOrderEditShippingPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-shipping'),
)
const DeliveryOrderEditInvoicePage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-invoice'),
)
const DeliveryOrderEditPaymentPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-payment'),
)
const DeliveryOrderEditAssetsPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-assets'),
)
const DeliveryOrderEditBoxesPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-boxes'),
)
const DeliveryOrderEditPackingPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-packing'),
)
const DeliveryOrderCreateCommercialOfferPage = lazy(
  () => import('@pages/delivery/page-delivery-order-create-commercial-offer'),
)
const DeliveryOrderEditCommercialOfferPage = lazy(
  () => import('@pages/delivery/page-delivery-order-edit-commercial-offer'),
)

// Categories
const CategoriesViewPage = lazy(() => import('@pages/categories/page-view'))

// Product subcategories
const ProductSubcategoriesViewPage = lazy(() => import('@pages/product-subcategories/page-view'))

// Brands
const BrandsViewPage = lazy(() => import('@pages/brands/page-view'))

// Updates
const UpdatesViewPage = lazy(() => import('@pages/updates/page-view'))

// Reports
const ReportsViewPage = lazy(() => import('@pages/reports/page-view'))

export const Router = () => (
  <Routes>
    <Route element={<PrivateRoute />} errorElement={<RouterError />}>
      <Route element={<MainLayout />}>
        <Route path={ROUTES.ORDERS.root}>
          <Route index element={<OrdersViewPage />} />

          <Route path='purchase/:id'>
            <Route index element={<PurchaseOrderDetailsViewPage />} />
            <Route
              path={ROUTES.ORDERS.purchase.editDelivery}
              element={<PurchaseOrderEditDeliveryPage />}
            />
            <Route
              path={ROUTES.ORDERS.purchase.editShipping}
              element={<PurchaseOrderEditShippingPage />}
            />
            <Route
              path={ROUTES.ORDERS.purchase.editPayment}
              element={<PurchaseOrderEditPaymentPage />}
            />
          </Route>

          <Route
            path={ROUTES.ORDERS.purchase.createCommercialOffer}
            element={<PurchaseOrderCreateCommercialOfferPage />}
          />

          <Route
            path={ROUTES.ORDERS.purchase.editCommercialOffer}
            element={<PurchaseOrderEditCommercialOfferPage />}
          />

          <Route
            path={ROUTES.ORDERS.purchase.offerAlternative}
            element={<PurchaseOrderOfferAlternativePage />}
          />
          <Route
            path={ROUTES.ORDERS.purchase.offerAlternativeManually}
            element={<PurchaseOrderOfferAlternativeManuallyPage />}
          />

          <Route path='delivery/:id'>
            <Route index element={<DeliveryOrderDetailViewPage />} />
            <Route
              path={ROUTES.ORDERS.delivery.editPickup}
              element={<DeliveryOrderEditPickupPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editDelivery}
              element={<DeliveryOrderEditDeliveryPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editShipping}
              element={<DeliveryOrderEditShippingPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editInvoice}
              element={<DeliveryOrderEditInvoicePage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editPayment}
              element={<DeliveryOrderEditPaymentPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editAssets}
              element={<DeliveryOrderEditAssetsPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editBoxes}
              element={<DeliveryOrderEditBoxesPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editPacking}
              element={<DeliveryOrderEditPackingPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.createCommercialOffer}
              element={<DeliveryOrderCreateCommercialOfferPage />}
            />
            <Route
              path={ROUTES.ORDERS.delivery.editCommercialOffer}
              element={<DeliveryOrderEditCommercialOfferPage />}
            />
          </Route>
          <Route path='*' element={<Navigate to={ROUTES.ORDERS.root} replace />} />
        </Route>

        <Route path={ROUTES.vendors}>
          <Route index element={<VendorsViewPage />} />
          <Route path={ROUTES.vendorCreate} element={<VendorCreatePage />} />
          <Route path={ROUTES.vendorEdit} element={<VendorEditPage />} />
          <Route path='*' element={<Navigate to={ROUTES.vendors} replace />} />
        </Route>

        <Route path={ROUTES.companies}>
          <Route index element={<CompaniesViewPage />} />
          <Route path='*' element={<Navigate to={ROUTES.companies} replace />} />
        </Route>

        <Route path={ROUTES.companiesAdmins}>
          <Route index element={<CompaniesAdminsViewPage />} />
          <Route path='*' element={<Navigate to={ROUTES.companiesAdmins} replace />} />
        </Route>

        <Route path={ROUTES.catalog}>
          <Route index element={<CatalogViewPage />} />
          <Route
            path={ROUTES.catalogProductCreateManually}
            element={<CatalogProductCreateManuallyPage />}
          />
          <Route
            path={ROUTES.catalogProductEditManually}
            element={<CatalogProductEditManuallyPage />}
          />
          <Route path='*' element={<Navigate to={ROUTES.catalog} replace />} />
        </Route>

        <Route path={ROUTES.categories.root}>
          <Route index element={<CategoriesViewPage />} />

          <Route path='*' element={<Navigate to={ROUTES.categories.root} replace />} />
        </Route>

        <Route path={ROUTES.subcategories.root}>
          <Route index element={<ProductSubcategoriesViewPage />} />

          <Route path='*' element={<Navigate to={ROUTES.subcategories.root} replace />} />
        </Route>

        <Route path={ROUTES.brands.root}>
          <Route index element={<BrandsViewPage />} />

          <Route path='*' element={<Navigate to={ROUTES.brands.root} replace />} />
        </Route>

        <Route path={ROUTES.updates.root}>
          <Route index element={<UpdatesViewPage />} />

          <Route path='*' element={<Navigate to={ROUTES.updates.root} replace />} />
        </Route>

        <Route path={ROUTES.reports.root}>
          <Route index element={<ReportsViewPage />} />

          <Route path='*' element={<Navigate to={ROUTES.reports.root} replace />} />
        </Route>

        <Route path='*' element={<Navigate to={ROUTES.ORDERS.root} replace />} />
      </Route>
    </Route>

    <Route path={ROUTES.login} element={<PublicRoute />}>
      <Route element={<AuthLayout />}>
        <Route index element={<LoginPage />} />
      </Route>
    </Route>

    <Route path='*' element={<Navigate to={ROUTES.login} replace />} />
  </Routes>
)
